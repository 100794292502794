interface DealsMap {
  [key: string]: string
}

export const dealsMap: DealsMap = {
  'Special Offer': 'Special Offer',
  'Cash Off': 'Cash Off',
  'New Lower Price': 'New Lower Price',
  'Waived Fees': 'Waived Fees',
  'Weeks Free': 'Weeks Free',
}

export const DEFAULT_HOT_DEAL_LABEL = 'Special Offer'
